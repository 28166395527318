<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card-actions-save
          ref="jobCard"
          no-body
          action-refresh
          action-save
          start-loading
          :title="$t('Manual Analysis.Job.title')"
          @refresh="refreshCardSwAl('jobCard')"
          @save="saveCard('jobCard')"
        >
          <b-card-body>
            <b-row class="justify-content-center">
              <b-card-group
                deck
                class="w-100"
              >
                <b-card
                  v-for="(doc, index) in documents.data"
                  :key="'doc_' + doc.title + '_' + index"
                  no-body
                  align="center"
                  border-variant="secondary"
                >
                  <b-card-header class="justify-content-center">
                    <h4 class="card-title">
                      {{ doc.title }}
                    </h4>
                  </b-card-header>
                  <b-card-body class="p-0">
                    <b-button
                      v-b-tooltip.hover.top="$t('Manual Analysis.Job.buttonTexts.download')"
                      variant="outline-primary"
                      size="lg"
                      class="btn-icon rounded-circle"
                      @click="downloadDocument(doc)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="21"
                      />
                    </b-button>
                  </b-card-body>
                  <b-card-footer class="border-top-0">
                    <b-row>
                      <b-col class="mb-1">
                        <b-button
                          v-b-modal="'modal_doc_' + index"
                          variant="outline-secondary btn-icon"
                        >
                          <feather-icon
                            icon="MessageSquareIcon"
                            class="text-primary"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group class="mb-0">
                          <b-form-radio-group
                            v-model="doc.status"
                            buttons
                            button-variant="outline-secondary btn-icon"
                          >
                            <template v-for="option in documents.radioOptions">
                              <b-form-radio
                                :key="'radio_' + doc.title + '_' + index + '_' + option.value"
                                v-b-tooltip.hover.top="$t(`Manual Analysis.Job.buttonTexts.${option.i18nKey}`)"
                                :value="option.value"
                                :disabled="status.status === statuses.done"
                              >
                                <feather-icon
                                  :icon="option.icon"
                                  :class="option.textColor"
                                />
                              </b-form-radio>
                            </template>
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- Modal here? -->
                    <b-modal
                      :id="'modal_doc_' + index"
                      hide-footer
                      centered
                      :title="$t('Manual Analysis.Job.modal.title')"
                    >
                      <b-form @submit.prevent>
                        <b-form-group
                          v-for="(comment, pos) in doc.comments.data"
                          :key="'doc_' + index + '_comment_' + pos"
                        >
                          <b-row>
                            <b-col>
                              <label :for="'doc_' + index + '_type-area_' + pos">{{ $t('Manual Analysis.Job.comments.type') }}</label>
                              <validation-provider
                                #default="{ errors }"
                                name="Type"
                                rules="required"
                              >
                                <b-form-input
                                  :id="'doc_' + index + '_type-area_' + pos"
                                  v-model="comment.type"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="$t('Manual Analysis.Job.comments.typePlaceholder')"
                                  :disabled="status.status === statuses.done"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                              <label :for="'doc_' + index + '_comment-area_' + pos">{{ $t('Manual Analysis.Job.comments.content') }}</label>
                              <validation-provider
                                #default="{ errors }"
                                name="Content"
                                rules="required"
                              >
                                <b-form-textarea
                                  :id="'doc_' + index + '_comment-area_' + pos"
                                  v-model="comment.content"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="$t('Manual Analysis.Job.comments.contentPlaceholder')"
                                  :disabled="status.status === statuses.done"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="2"
                              align-self="center"
                            >
                              <b-button
                                size="lg"
                                variant="flat-secondary btn-icon"
                                :disabled="status.status === statuses.done"
                                @click="saveDocComment(doc, pos)"
                              >
                                <feather-icon
                                  icon="SaveIcon"
                                  class="text-primary"
                                  size="21"
                                />
                              </b-button>
                              <b-button
                                v-if="comment.id"
                                size="lg"
                                variant="flat-secondary btn-icon"
                                :disabled="status.status === statuses.done"
                                @click="deleteDocComment(doc, pos)"
                              >
                                <feather-icon
                                  icon="DeleteIcon"
                                  class="text-primary"
                                  size="21"
                                />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-button
                          variant="outline-primary"
                          :disabled="status.status === statuses.done"
                          @click="addDocComment(doc.comments)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                        </b-button>
                        &nbsp;
                        <b-button
                          variant="outline-primary"
                          :disabled="status.status === statuses.done"
                          @click="removeDocComment(doc.comments)"
                        >
                          <feather-icon
                            icon="MinusIcon"
                          />
                        </b-button>
                      </b-form>
                    </b-modal>
                  </b-card-footer>
                </b-card>
              </b-card-group>
            </b-row>
            <b-row class="justify-content-center">
              <b-card-group
                deck
                class="w-100"
              >
                <b-card
                  v-for="(_, index) in mods"
                  :key="`mod_${index}`"
                  no-body
                  align="center"
                  border-variant="secondary"
                >
                  <b-card-header class="justify-content-center">
                    <h4 class="card-title">
                      {{ `Modificación N° ${index + 1}` }}
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-button
                      v-b-tooltip.hover.top="$t('Manual Analysis.Job.buttonTexts.download')"
                      variant="outline-primary"
                      size="lg"
                      class="btn-icon rounded-circle"
                      @click="downloadMod(index)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="21"
                      />
                    </b-button>
                  </b-card-body>
                </b-card>
              </b-card-group>
            </b-row>
            <b-row>
              <b-col>
                <b-card
                  :title="$t('Manual Analysis.Job.form.title')"
                  border-variant="secondary"
                >
                  <validation-observer
                    ref="jobFormObserver"
                  >
                    <b-form @submit.prevent>
                      <b-form-group
                        v-for="(element, position) in form.content"
                        :key="element.label + position"
                        :label="element.label"
                        :label-for="'form_' + element.label + '_' + position"
                        label-cols-sm="5"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-cols-xl="2"
                      >
                        <b-form-input
                          v-if="element.type === 'input'"
                          :id="'form_' + element.label + '_' + position"
                          v-model="element.value"
                          :placeholder="element.placeholder"
                          :disabled="status.status === statuses.done"
                          class="m-0"
                        />
                        <b-form-textarea
                          v-else-if="element.type === 'textarea'"
                          :id="'form_' + element.label + '_' + position"
                          v-model="element.value"
                          :placeholder="element.placeholder"
                          :disabled="status.status === statuses.done"
                        />
                        <b-form-checkbox
                          v-else-if="element.type === 'switch'"
                          :id="'form_' + element.label + '_' + position"
                          v-model="element.value"
                          :disabled="status.status === statuses.done"
                          switch
                        />
                        <b-form-checkbox-group
                          v-else-if="element.type === 'switch-group'"
                          :id="'form_' + element.label + '_' + position"
                          v-model="element.value"
                          style="column-count: 2;"
                          :disabled="status.status === statuses.done"
                          :options="element.options"
                          switches
                          stacked
                          class="pt-6"
                        />
                        <div
                          v-else-if="element.type === 'admin-repeater'"
                          :id="'form_' + element.label + '_' + position"
                        >
                          <b-row
                            v-for="(adminRow, index) in element.value"
                            :key="'admin_' + index + '_form'"
                          >
                            <b-col>
                              <b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Name"
                                  rules="required"
                                >
                                  <b-form-input
                                    :id="'admin_' + index + '_name'"
                                    v-model="adminRow.name"
                                    :state="errors.length > 0 ? false:null"
                                    :placeholder="$t('Manual Analysis.Job.form.placeholders.name')"
                                    :disabled="status.status === statuses.done"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  name="RUT"
                                  rules="required|rut"
                                >
                                  <b-form-input
                                    :id="'admin_' + index + '_rut'"
                                    v-model="adminRow.rut"
                                    :state="errors.length > 0 ? false:null"
                                    lazy-formatter
                                    :formatter="rutFormatter"
                                    :placeholder="$t('Manual Analysis.Job.form.placeholders.rut')"
                                    :disabled="status.status === statuses.done"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-button
                            variant="outline-primary"
                            size="sm"
                            :disabled="status.status === statuses.done"
                            @click="addAdminRow(element.value)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                            />
                          </b-button>
                          &nbsp;
                          <b-button
                            variant="outline-primary"
                            size="sm"
                            :disabled="status.status === statuses.done"
                            @click="deleteAdminRow(element.value)"
                          >
                            <feather-icon
                              icon="MinusIcon"
                            />
                          </b-button>
                        </div>
                        <b-form-group
                          v-else-if="element.type === 'rut'"
                          :id="'form_' + element.label + '_' + position"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="RUT"
                            rules="rut"
                          >
                            <b-form-input
                              v-model="element.value"
                              :state="errors.length > 0 ? false:null"
                              lazy-formatter
                              :formatter="rutFormatter"
                              :placeholder="element.placeholder"
                              disabled
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          v-else-if="element.type === 'positive-double'"
                          :id="'form_' + element.label + '_' + position"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="toValidate"
                            rules="double|positive"
                          >
                            <b-form-input
                              v-model="element.value"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="element.placeholder"
                              :disabled="status.status === statuses.done"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          v-else-if="element.type === 'positive-integer'"
                          :id="'form_' + element.label + '_' + position"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="toValidate"
                            rules="integer|positive"
                          >
                            <b-form-input
                              v-model="element.value"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="element.placeholder"
                              :disabled="status.status === statuses.done"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          v-else-if="element.type === 'date'"
                          :id="'form_' + element.label + '_' + position"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="toValidate"
                            rules="date"
                          >
                            <b-input-group>
                              <b-form-input
                                :id="'date_' + position"
                                v-model="element.value"
                                type="text"
                                placeholder="YYYY-MM-DD"
                                autocomplete="off"
                                :state="errors.length > 0 ? false:null"
                                :disabled="status.status === statuses.done"
                                lazy-formatter
                                :formatter="dateFormatter"
                              />
                              <b-input-group-append>
                                <b-form-datepicker
                                  v-model="element.value"
                                  :state="errors.length > 0 ? false:null"
                                  button-only
                                  right
                                  size="sm"
                                  :button-variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'"
                                  :aria-controls="'date_' + position"
                                  :disabled="status.status === statuses.done"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- Done: Add else statemtent -->
                        <b-form-input
                          v-else
                          :id="'form_' + element.label + '_' + position"
                          :value="$t('Manual Analysis.Job.form.unknownType')"
                          readonly
                          class="m-0"
                        />
                        <!-- Consider supporting form repeaters -->
                      </b-form-group>
                    </b-form>
                  </validation-observer>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card
                  :title="$t('Manual Analysis.Job.comments.title')"
                  border-variant="secondary"
                >
                  <validation-observer
                    ref="jobCommentObserver"
                  >
                    <b-form @submit.prevent>
                      <b-form-group
                        v-for="(element, index) in comments.data"
                        :key="'comment_' + index + '_full'"
                        :label="'N° ' + ((comments.pagination.page - 1) * comments.pagination.page_size + index + 1)"
                        label-cols-sm="5"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-cols-xl="2"
                      >
                        <b-form-group
                          :label="$t('Manual Analysis.Job.comments.type')"
                          :label-for="'comment_' + index + '_type'"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Type"
                            rules="required"
                          >
                            <b-form-input
                              :id="'comment_' + index + '_type'"
                              v-model="element.type"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('Manual Analysis.Job.comments.typePlaceholder')"
                              :disabled="status.status === statuses.done"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          :label="$t('Manual Analysis.Job.comments.content')"
                          :label-for="'comment_' + index + 'content'"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Content"
                            rules="required"
                          >
                            <b-form-textarea
                              :id="'comment_' + index + 'content'"
                              v-model="element.content"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('Manual Analysis.Job.comments.contentPlaceholder')"
                              :disabled="status.status === statuses.done"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-form-group>
                      <b-button
                        variant="outline-primary"
                        :disabled="status.status === statuses.done"
                        @click="addJobComment"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                      </b-button>
                      &nbsp;
                      <b-button
                        variant="outline-primary"
                        :disabled="status.status === statuses.done"
                        @click="removeJobComment"
                      >
                        <feather-icon
                          icon="MinusIcon"
                        />
                      </b-button>
                    </b-form>
                  </validation-observer>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <b-button
                  variant="outline-secondary"
                  :disabled="status.status === statuses.done"
                  @click="lockCardSwAl('jobCard')"
                >
                  <feather-icon
                    icon="LockIcon"
                    class="text-danger"
                  />
                </b-button>
              </b-col>
              <b-col
                v-if="$can('manage', 'all')"
                class="text-center"
              >
                <b-button
                  variant="outline-secondary"
                  :disabled="status.status !== statuses.done"
                  @click="unlockCardSwAl('jobCard')"
                >
                  <feather-icon
                    icon="UnlockIcon"
                    class="text-success"
                  />
                </b-button>
              </b-col>
            </b-row>
            <!-- Alert, to warn in case of error -->
            <b-alert
              v-model="showAlert"
              v-height-fade.appear
              variant="danger"
              dismissible
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertOctagonIcon"
                />
                <span><strong>{{ $t("Reports.Overview.alert.title") }}:</strong> {{ $t("Reports.Overview.alert.message") }}</span>
              </div>
            </b-alert>
          </b-card-body>
        </b-card-actions-save>
      </b-col>
    </b-row>
    <b-modal
      id="modal-comment"
      v-model="showCommentModal"
      cancel-variant="outline-secondary"
      :ok-title="$t('Manual Analysis.Job.modal.buttonTexts.ok')"
      :cancel-title="$t('Manual Analysis.Job.modal.buttonTexts.cancel')"
      centered
      :title="$t('Manual Analysis.Job.modal.title')"
    >
      <b-form>
        <b-form-group>
          <label for="type-area">{{ $t('Manual Analysis.Job.comments.type') }}</label>
          <b-form-input
            id="type-area"
            :placeholder="$t('Manual Analysis.Job.comments.typePlaceholder')"
          />
          <label for="comment-area">{{ $t('Manual Analysis.Job.comments.content') }}</label>
          <b-form-textarea
            id="comment-area"
            :placeholder="$t('Manual Analysis.Job.comments.contentPlaceholder')"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, requiredIf, rut, double, positive, integer,
} from '@validations'
import {
  BRow, BCol, BCard, BCardBody, BCardFooter, BCardHeader, BCardGroup, BAlert, BButton, BFormGroup, BFormRadioGroup, BFormRadio, VBTooltip,
  BFormInput, BForm, BFormTextarea, BFormCheckbox, BFormCheckboxGroup, BModal, VBModal, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import BCardActionsSave from '@/custom-components/b-card-actions-save/BCardActionsSave.vue'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import { formatRut, RutFormat } from '@fdograph/rut-utilities'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { parse, isValid, formatISO } from 'date-fns'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCardGroup,
    BAlert,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCardActionsSave,
    BModal,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    heightFade,
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      // Validators
      required,
      requiredIf,
      rut,
      positive,
      double,
      integer,
      // Data
      documents: {
        data: [
          // {
          //   title: 'Doc 1',
          //   status: '',
          //   doc_id: '',
          //   comments: {
          //     data: [{ type: 'Algo', content: 'Algo' }, { type: 'Otro', content: 'Otro' }],
          //   },
          // },
          // {
          //   title: 'Doc 2',
          //   status: '',
          //   doc_id: '',
          //   comments: {
          //     data: [{ type: 'Algo', content: 'Algo' }, { type: 'Otro', content: 'Otro' }],
          //   },
          // },
          // {
          //   title: 'Doc 3',
          //   status: '',
          //   doc_id: '',
          //   comments: {
          //     data: [{ type: 'Algo', content: 'Algo' }, { type: 'Otro', content: 'Otro' }],
          //   },
          // },
          // {
          //   title: 'Doc 4',
          //   status: '',
          //   doc_id: '',
          //   comments: {
          //     data: [{ type: 'Algo', content: 'Algo' }, { type: 'Otro', content: 'Otro' }],
          //   },
          // },
        ],
        radioOptions: [
          {
            text: '1', value: 'aprobado', icon: 'CheckIcon', textColor: 'text-success', i18nKey: 'approved',
          },
          {
            text: '2', value: 'neutro', icon: 'MinusIcon', textColor: 'text-info', i18nKey: 'pending',
          },
          {
            text: '3', value: 'rechazado', icon: 'XIcon', textColor: 'text-danger', i18nKey: 'rejected',
          },
        ],
      },
      form: {
        id: -1,
        content: [
          // {
          //   type: 'input', value: '', placeholder: '', label: 'Razon social',
          // },
          // {
          //   type: 'input', value: '', placeholder: '', label: 'Nombre de fantasia',
          // },
          // {
          //   type: 'rut', value: '', placeholder: '12345678-9', label: 'Rut',
          // },
          // {
          //   type: 'date', value: '', placeholder: 'aaaa-mm-dd', label: 'Fecha',
          // },
          // {
          //   type: 'input', value: '', placeholder: '', label: 'Domicilio social',
          // },
          // {
          //   type: 'input', value: '', placeholder: '', label: 'Forma de administracion',
          // },
          // {
          //   type: 'textarea', value: '', placeholder: 'Nombre1, RUT 11111111-1\nNombre2, RUT 22222222-2', label: 'Administradores',
          // },
          // {
          //   type: 'positive-double', value: '', placeholder: '1111.1111', label: 'Positive double',
          // },
          // {
          //   type: 'positive-integer', value: '', placeholder: '11111111', label: 'Positive integer',
          // },
          // {
          //   type: 'admin-repeater',
          //   label: 'Administradores',
          //   value: [
          //     { rut: '17.753.648-2', name: 'David Patricio Tapia Otarola' },
          //   ],
          // },
          // {
          //   type: 'switch-group',
          //   value: [],
          //   label: 'Facultades',
          //   options: [
          //     { text: 'Avalar letras de cambio/avalar pagares', value: 'Puede ser lo mismo (en cuyo caso se puede omitir) u otro identificador... TBD' },
          //     { text: 'Contratar préstamos o líneas de crédito', value: 'Contratar préstamos o líneas de crédito' },
          //     { text: 'Delegar todo el poder', value: 'Delegar todo el poder' },
          //     { text: 'Otorgar poderes generales', value: 'Otorgar poderes generales' },
          //     { text: 'Suscribir/resuscribir pagarés', value: 'Suscribir/resuscribir pagarés' },
          //     { text: 'Constituir garantías', value: 'Constituir garantías' },
          //     { text: 'Delegar parte del poder', value: 'Delegar parte del poder' },
          //     { text: 'Otorgar poderes especiales', value: 'Otorgar poderes especiales' },
          //     { text: 'Otorgar prendas', value: 'Otorgar prendas' },
          //   ],
          // },
          // {
          //   type: 'switch', value: false, label: 'Criterio MAF',
          // },
        ],
      },
      comments: {
        data: [
          // {
          //   id: 345,
          //   type: 'observacion/etc...',
          //   content: 'Este es un comentario de job',
          // },
        ],
        pagination: {},
      },
      status: {}, // Will be used when locking the job
      mods: [],
      showAlert: false,
      jobID: -1,
      showCommentModal: false,
      statuses: {
        pending: 0,
        withObservations: 1,
        done: 2,
      },
    }
  },
  created() {
    this.jobID = this.$route.params.id
    this.getMods()
    this.$nextTick(() => {
      this.refreshCard('jobCard')
    })
  },
  methods: {
    printProperties() {
      // console.log(this.form)
      // console.log('*')
      // console.log(this.documents)
      // console.log('*')
      // console.log(this.comments)
      // console.log('*')
      // console.log(this.status)
      // console.log(this.validateAdminPresence())
      this.lockCardSwAl('jobCard')
    },
    getMods() {
      this.$http.get('/v2/ep-mod', {
        params: {
          id: this.jobID,
        },
      })
        .then(response => {
          console.log(response.data)
          this.mods = response.data.mods
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    assignProperties(data) {
      this.status = data.status
      this.documents.data = data.documents
      this.form = data.form
      this.comments = data.comments
    },
    dateFormatter(text) {
      // DONE: - Accept EEUD format
      //       - Make value the default one
      const asDate = parse(text, 'yyyy-MM-dd', new Date())
      if (isValid(asDate)) {
        return formatISO(asDate, { representation: 'date' })
      }
      return text
    },
    rutFormatter(text) {
      return formatRut(text, RutFormat.DOTS_DASH)
    },
    getOverview() {
      this.showAlert = false
      return this.$http.get(`/v2/manual/jobs/${this.jobID}`)
        .then(response => {
          // console.log(response.data)
          this.assignProperties(response.data)
        })
        .catch(error => {
          console.error('There was an error: ', error)
          this.showAlert = true
        })
    },
    putJob(status) {
      return this.$http.put(`/v2/manual/jobs/${this.jobID}`,
        {
          form: this.form,
          comments: this.comments.data,
          documents_status: this.documents.data,
          ...(
            status ? { job_status: { status } } : {}
          ),
        })
        .then(response => {
          // console.log(response.data)
          this.assignProperties(response.data)
        })
        .catch(error => {
          console.error('There was an error: ', error)
          this.showAlert = true
        })
    },
    putStatusOnJob(status) {
      return this.$http.put(`/v2/manual/jobs/${this.jobID}`,
        {
          job_status: { status },
        })
        .then(response => {
          // console.log(response.data)
          this.assignProperties(response.data)
        })
        .catch(error => {
          console.error('There was an error: ', error)
          this.showAlert = true
        })
    },
    downloadDocument(doc) {
      this.$http.get(`/v2/manual/jobs/${this.jobID}/doc/${doc.doc_id}`, {
        responseType: 'blob',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })

          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${doc.title}.pdf`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          this.makeToast(
            this.$i18n.t('Toast.titles.errNoID'), // Title
            this.$i18n.t('Toast.texts.couldntDownload'), // Text
            'danger', // Variant
          )
        })
    },
    downloadMod(index) {
      this.$http.get('/v2/ep-mod-pdf', {
        params: {
          id: this.jobID,
          index,
        },
      })
        .then(response => {
          const link = document.createElement('a')
          link.href = `data:application/pdf;base64,${response.data.data}`
          link.download = `${this.jobID}_mod_${index + 1}.pdf`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          this.makeToast(
            this.$i18n.t('Toast.titles.errNoID'), // Title
            this.$i18n.t('Toast.texts.couldntDownload'), // Text
            'danger', // Variant
          )
        })
    },
    makeToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    saveDocComment(doc, commentIndex) {
      const comment = doc.comments.data[commentIndex]
      if (comment.id) {
        this.$http.put(
          `/v2/manual/jobs/${this.jobID}/doc/${doc.doc_id}/comments/${comment.id}`,
          comment,
        )
          .then(response => {
            comment.type = response.data.type
            comment.content = response.data.content
            this.makeToast(
              this.$i18n.t('Toast.titles.saved'), // Title
              this.$i18n.t('Toast.texts.successSave'), // Text
              'success', // Variant
            )
          })
          .catch(error => {
            console.error('There was an error: ', error)
            this.makeToast(
              this.$i18n.t('Toast.titles.errNoID'), // Title
              this.$i18n.t('Toast.texts.couldntSave'), // Text
              'danger', // Variant
            )
          })
        console.log(comment)
      } else {
        this.$http.post(
          `/v2/manual/jobs/${this.jobID}/doc/${doc.doc_id}/comments`,
          comment,
        )
          .then(response => {
            comment.id = response.data.id
            comment.type = response.data.type
            comment.content = response.data.content
            this.makeToast(
              this.$i18n.t('Toast.titles.saved'), // Title
              this.$i18n.t('Toast.texts.successSave'), // Text
              'success', // Variant
            )
            console.log(doc.comments.data[commentIndex])
          })
          .catch(error => {
            console.error('There was an error: ', error)
            this.makeToast(
              this.$i18n.t('Toast.titles.errNoID'), // Title
              this.$i18n.t('Toast.texts.couldntSave'), // Text
              'danger', // Variant
            )
          })
      }
    },
    deleteDocComment(doc, commentIndex) {
      const comment = doc.comments.data[commentIndex]
      this.$http.delete(
        `/v2/manual/jobs/${this.jobID}/doc/${doc.doc_id}/comments/${comment.id}`,
      )
        .then(response => {
          // DONE: Toast displaying info
          this.makeToast(
            this.$i18n.t('Toast.titles.deleted'), // Title
            this.$i18n.t('Toast.texts.successDelete'), // Text
            'success', // Variant
          )
          // Meaningless, I'm considering using the data in the toast
          // Someday, in the faaaaar future
          comment.type = response.data.type
          comment.content = response.data.content
          doc.comments.data.splice(commentIndex, 1) // Probably invalidates comment ref above
        })
        .catch(error => {
          console.error('There was an error: ', error)
          this.makeToast(
            this.$i18n.t('Toast.titles.errNoID'), // Title
            this.$i18n.t('Toast.texts.couldntDelete'), // Text
            'danger', // Variant
          )
        })
      console.log(comment)
    },
    addDocComment(comments) {
      const ref = comments
      ref.data.push({ id: 0, type: '', content: '' })
    },
    removeDocComment(comments) {
      const last = comments.data[comments.data.length - 1]
      if (last && !last.type && !last.content) {
        comments.data.pop()
      }
    },
    addJobComment() {
      this.comments.data.push({ type: '', content: '' })
    },
    removeJobComment() {
      const last = this.comments.data[this.comments.data.length - 1]
      if (last && !last.type && !last.content) {
        this.comments.data.pop()
      }
    },
    addAdminRow(adminList) {
      adminList.push({ name: '', rut: '' })
    },
    deleteAdminRow(adminList) {
      const last = adminList[adminList.length - 1]
      if (last && !last.name && !last.rut) {
        adminList.pop()
      }
    },
    showConfimationSweetAlert(message) {
      return this.$swal({
        title: this.$i18n.t('SwAl.titles.confirmation'),
        html: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('SwAl.buttonTexts.yes'),
        cancelButtonText: this.$i18n.t('SwAl.buttonTexts.no'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
    },
    showErrorSweetAlert(couldValidate) {
      return this.$swal({
        icon: 'error',
        title: this.$i18n.t('SwAl.titles.error'),
        html: couldValidate ? this.$i18n.t('SwAl.htmls.verifyData') : this.$i18n.t('Reports.Overview.alert.message'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    validateAll() {
      return Promise.all([
        this.$refs.jobCommentObserver.validate(),
        this.$refs.jobFormObserver.validate(),
      ])
    },
    validateAdminPresence() {
      // console.log(this.form.content.filter(item => item.type === 'admin-repeater'))
      return this.form.content
        .filter(item => item.type === 'admin-repeater')
        .every(item => item.value.length > 0)
    },
    saveCard(cardName) {
      if (this.status.status !== this.statuses.done) {
        this.validateAll()
          .then(values => {
            if (values.every(element => element)) {
              this.showConfimationSweetAlert(this.$i18n.t('SwAl.htmls.confirmSave'))
                .then(result => {
                  if (result.value) {
                    this.putJob()
                      .finally(() => {
                        this.$refs[cardName].showLoading = false
                      })
                  } else {
                    this.$refs[cardName].showLoading = false
                  }
                })
            } else {
              this.showErrorSweetAlert(true).finally(() => {
                this.$refs[cardName].showLoading = false
              })
            }
          })
          .catch(error => {
            console.error('There was an error: ', error)
            this.showErrorSweetAlert(false).finally(() => {
              this.$refs[cardName].showLoading = false
            })
          })
      }
      this.$refs[cardName].showLoading = false
    },
    refreshCard(cardName) {
      this.getOverview().finally(() => {
        this.$refs[cardName].showLoading = false
      })
    },
    lockCardSwAl(cardName) {
      this.validateAll()
        .then(values => {
          if (values.every(element => element) && this.validateAdminPresence()) {
            this.showConfimationSweetAlert(this.$i18n.t('SwAl.htmls.confirmLock'))
              .then(result => {
                if (result.value) {
                  this.$refs[cardName].showLoading = true
                  this.putJob(this.statuses.done)
                    .finally(() => {
                      this.$refs[cardName].showLoading = false
                    })
                }
              })
          } else {
            this.showErrorSweetAlert(true)
          }
        })
        .catch(error => {
          console.error('There was an error: ', error)
          this.showErrorSweetAlert(false)
        })
    },
    unlockCardSwAl(cardName) {
      this.showConfimationSweetAlert(this.$i18n.t('SwAl.htmls.confirmUnlock'))
        .then(result => {
          if (result.value) {
            this.$refs[cardName].showLoading = true
            this.putStatusOnJob(this.statuses.pending)
              .finally(() => {
                this.$refs[cardName].showLoading = false
              })
          }
        })
    },
    refreshCardSwAl(cardName) {
      this.$swal({
        title: this.$i18n.t('SwAl.titles.confirmation'),
        html: this.$i18n.t('SwAl.htmls.confirmRefresh'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('SwAl.buttonTexts.yes'),
        cancelButtonText: this.$i18n.t('SwAl.buttonTexts.no'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.refreshCard(cardName)
          } else {
            this.$refs[cardName].showLoading = false
          }
        })
        .catch(error => {
          console.error('There was an error: ', error)
          this.showErrorSweetAlert(false).finally(() => {
            this.$refs[cardName].showLoading = false
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
